import {
  RawDraftContentBlock,
  RawDraftContentState,
  RawDraftEntity,
} from 'draft-js';

import { AddCityType, AddLocationType } from 'src/actions/companySearch';
import { CountryCodes } from 'src/common/enums';
import { CompanyStatus } from 'src/common/enums/companies';
import { unaccentTerm } from 'src/common/helpers';

import { specialRegionsForCountry } from '../Opportunities/pages/Explore/Components/SearchSection/LocationSearchField/helpers';
import { LocationType } from '../Opportunities/pages/Explore/Components/SearchSection/LocationSearchField/NewSearchLocation';

export const serializeLocationProp = (prop: string[]): string => {
  return prop && prop.length ? prop.map((p) => p).join(',') : undefined;
};

export const unserializeLocationProp = (prop: string): string[] => {
  return prop && prop.length ? prop.split(',').map((p) => p.trim()) : undefined;
};

export function serializeSelectedLocations<T>(prop: T[]): string {
  if (!prop || !prop.length) {
    return undefined;
  }
  try {
    return encodeURIComponent(JSON.stringify(prop)) as string;
  } catch (_error) {
    return undefined;
  }
}

export function unserializeSelectedLocations<T>(prop: string): T[] {
  try {
    const decoded = JSON.parse(decodeURIComponent(prop)) as T[];
    return decoded && decoded.length ? decoded : undefined;
  } catch (_error) {
    return undefined;
  }
}

export const unserializePage = (locationPage: string) => {
  const parsedPage = locationPage && parseInt(locationPage);
  return parsedPage || 1;
};

export const getFetchCompaniesParams = ({
  locationKeyword,
  locationCompanies,
  locationCities,
  selectedLocations,
  locationCountries,
  locationOrder,
  locationPage,
}: {
  [key: string]: string;
}) => {
  const page = unserializePage(locationPage);

  const unserializedLocations =
    unserializeSelectedLocations<AddLocationType>(selectedLocations);

  const locationIds =
    unserializedLocations && unserializedLocations.length
      ? unserializedLocations
          .map((item) => {
            if (item?.type !== LocationType.Default) {
              const region = specialRegionsForCountry(
                (item?.country as CountryCodes.ID) ?? CountryCodes.ID
              ).find((regionItem) => item.id === regionItem.id);

              if (region) return region.cities;
            }
            return item.id;
          })
          .flat()
      : undefined;

  const unserializedCities =
    unserializeSelectedLocations<AddCityType>(locationCities);
  const cityIds =
    unserializedCities && unserializedCities.length
      ? unserializedCities.map((item) => item.id)
      : undefined;

  const params = {
    where: {
      status: CompanyStatus.VERIFIED,
      name: locationKeyword
        ? { ilike: `%${unaccentTerm(locationKeyword)}%` }
        : { not: null as null },
      id: unserializeLocationProp(locationCompanies),
      CityId: cityIds,
      LocationId: locationIds,
      CountryCode: locationCountries,
    },
    order: locationOrder ? locationOrder : 'magic',
  };
  return { page, params };
};

/** Handle extract text blocks and image blocks in DraftJS Draw Object
 *  [filterNonEmptyBlocks] helps remove images blocks in blocks,
 *  it will prevent to render enpty <div></div> on screen
 *
 *  [filterNonImageEntities] helps to return [entitiesMap]
 *  that is not [DraftEntityType] type `IMAGE`
 *
 *  [extractImagesUrls] helps to return a list of `imageURL` will used in Gallery Component
 *
 *  [getDescriptionRawAndImages] will return a new object
 *  1. selectedDescriptionRaw will be a new Raw (including text only), be rendered in DraftJS
 *  2. imagesInRaw will be an Array of imageURL, be rendered in Gallery Component
 */

const filterNonEmptyBlocks = (blocks: RawDraftContentBlock[]) =>
  blocks.filter((block) => block.text.trim() !== '');

const filterNonImageEntities = (entityMap: {
  [key: string]: RawDraftEntity;
}) => {
  return Object.entries(entityMap).reduce(
    (acc, [key, entity]) => {
      if (entity.type !== 'IMAGE') {
        acc[key] = entity;
      }
      return acc;
    },
    {} as { [key: string]: RawDraftEntity }
  );
};

const extractImagesUrls = (entityMap: { [key: string]: RawDraftEntity }) => {
  return Object.values(entityMap)
    .filter((entity) => entity.type === 'IMAGE')
    .map((entity) => entity.data.src)
    .filter(
      (src): src is string => src !== undefined && src?.includes('https')
    );
};

export const getDescriptionRawAndImages = (
  descriptionRaw?: RawDraftContentState
) => {
  if (!descriptionRaw) {
    return { selectedDescriptionRaw: undefined, imagesInRaw: [] };
  }

  const selectedDescriptionRaw: RawDraftContentState = {
    blocks: filterNonEmptyBlocks(descriptionRaw.blocks),
    entityMap: filterNonImageEntities(descriptionRaw.entityMap),
  };

  const imagesInRaw = extractImagesUrls(descriptionRaw.entityMap);

  return { selectedDescriptionRaw, imagesInRaw };
};

export const isDraftStateEmpty = (
  rawContent?: RawDraftContentState
): boolean => {
  if (!rawContent) return true;
  return (
    rawContent.blocks.every((block) => !block.text.trim()) &&
    Object.keys(rawContent.entityMap).length === 0
  );
};
